import React, { useState } from 'react';

import { makeStyles, Toolbar, Grid, Typography } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import AppDrawer from './AppDrawer';
import TopAppBar from './TopAppBar';
import { appRoutes } from '../../common/appRoutes';
import ManageUsers from '../ManageUsers';
import ManageDevices from '../ManageDevices';
import ManageDeviceAssignments from '../ManageDeviceAssignments';
import { useLoginAccess } from '../../providers/LoginAccessProvider';
import ContactTracingReportPage from '../reports/ContactTracingReports/ContactTracingReportPage';
import Dashboard from '../reports/DashboardReports/Dashboard';
import ContactOverTimeReports from '../reports/ContactOverTimeReports/ContactOverTimeReports';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    width: '100px', // Ignored but needed for e.g. charts to size properly
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const App = () => {
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { isLoading, isAuthenticated } = useAuth0();

  const { hasOrganisationPermissions } = useLoginAccess();

  if (isLoading || !isAuthenticated) {
    return <p></p>;
  }

  if (hasOrganisationPermissions === undefined) {
    return <p></p>;
  }

  if (!hasOrganisationPermissions) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Typography>No access.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div id="app" className={classes.root}>
      <TopAppBar
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      ></TopAppBar>

      <AppDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      ></AppDrawer>

      <main className={classes.content}>
        <Toolbar />

        <Switch>
          <Redirect exact from="/" to={appRoutes.dashboard} />

          <Route path={appRoutes.dashboard} component={Dashboard}></Route>

          <Route
            path={appRoutes.contactReports}
            component={ContactOverTimeReports}
          ></Route>

          <Route
            path={appRoutes.reportsContactTracing}
            component={ContactTracingReportPage}
          ></Route>

          <Route path={appRoutes.manageUsers} component={ManageUsers}></Route>

          <Route path={appRoutes.viewDevices} component={ManageDevices}></Route>

          <Route
            path={appRoutes.manageDeviceAssignments}
            component={ManageDeviceAssignments}
          ></Route>
        </Switch>
      </main>
    </div>
  );
};

export default App;
