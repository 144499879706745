import React, { useContext, createContext, useState } from 'react';

import {
  makeStyles,
  Snackbar,
  SnackbarContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

const useStyles = makeStyles((theme) => ({
  simpleSnackbar: {
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      bottom: '4.6rem',
    },
  },
  textColor: {
    color: '#fff',
  },
  error: {
    backgroundColor: '#eb5a46',
  },
}));

export const SnackbarProvider = ({ children }) => {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  const onSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  };

  const showSnackbar = (message) => {
    if (!snackbar.open) {
      showSuccessSnackbar(message);
    }
  };

  const showSuccessSnackbar = (message) => {
    setSnackbar({
      open: true,
      message: message,
    });
  };

  const hideSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
    });
  };

  const snackbarMessage = (
    <Typography
      variant="body1"
      className={classNames(classes.textColor)}
      id="message-id"
    >
      {snackbar.message}
    </Typography>
  );

  const snackbarAction = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={onSnackbarClose}
    >
      <CloseIcon />
    </IconButton>,
  ];

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        className={classes.simpleSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={4000}
        disableWindowBlurListener={true}
        transitionDuration={{ enter: 200, exit: 200 }}
        onClose={onSnackbarClose}
      >
        <SnackbarContent message={snackbarMessage} action={snackbarAction} />
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
