import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import { constants } from '../../../../common/constants';

const useStyles = makeStyles({
  dropdown: {
    width: '100%',
  },
});

const GraphScaleSelect = ({ selectedValue, onValueChanged }) => {
  const classes = useStyles();

  const onChange = (event) => {
    onValueChanged(event.target.value);
  };

  return (
    <FormControl className={classes.dropdown}>
      <InputLabel id="graph-scale-select-label">Max</InputLabel>
      <Select
        labelId="graph-scale-select-label"
        value={selectedValue}
        onChange={onChange}
      >
        <MenuItem value={constants.graphScaleAutoValue}>Auto</MenuItem>
        <MenuItem value={120}>120</MenuItem>
        <MenuItem value={60}>60</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={15}>15</MenuItem>
      </Select>
    </FormControl>
  );
};

export default GraphScaleSelect;
