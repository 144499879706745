import React, { createContext, useContext, useState, useRef } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
} from '@material-ui/core';

const DialogContext = createContext();
export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [password, setPassword] = useState('');
  const textFieldRef = useRef();
  const passwordRef = useRef();

  const onTextFieldChange = (event) => {
    setPassword(event.target.value);
  };

  const openDialog = (title, content) => {
    setTitle(title);
    setContent(content);
    setPassword('');

    setIsDialogOpen(true);
    setTimeout(() => textFieldRef.current.focus(), 100);
    return new Promise((resolve) => {
      passwordRef.current = resolve;
    });
  };

  const onDialogClose = () => {
    closeDialog();
    passwordRef.current(null);
  };

  const onDialogConfirm = () => {
    confirmDialog();
  };

  const onTextFieldKeyPress = (event) => {
    if (event.key === 'Enter') {
      confirmDialog();
    }
  };

  const confirmDialog = () => {
    closeDialog();
    passwordRef.current(password);
  };

  const closeDialog = () => {
    setTitle('');
    setContent('');
    setIsDialogOpen(null);
  };

  return (
    <DialogContext.Provider
      value={{
        openDialog,
      }}
    >
      {children}
      <Dialog open={isDialogOpen} onClose={onDialogClose} fullWidth={true}>
        <DialogTitle data-cy="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText data-cy="dialog-content">
            {content}
          </DialogContentText>
          <TextField
            inputRef={textFieldRef}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={onTextFieldChange}
            fullWidth
            onKeyPress={onTextFieldKeyPress}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDialogClose}
            color="primary"
            data-cy="dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={onDialogConfirm}
            color="primary"
            data-cy="dialog-ok-button"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};
