import React from 'react';
import { NavLink } from 'react-router-dom';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

const AppDrawerLink = ({ icon, text, target }) => {
  return (
    <MenuItem
      button
      to={target}
      component={NavLink}
      activeClassName="Mui-selected"
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default AppDrawerLink;
