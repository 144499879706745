import React, { useEffect } from 'react';

import {
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { useLoginAccess } from '../providers/LoginAccessProvider';

const useStyles = makeStyles({
  select: {
    minWidth: '10rem',
  },
});

const OrganisationSelector = () => {
  const classes = useStyles();

  const {
    loginOrganisationPermissions,
    selectedOrganisationId,
    setSelectedOrganisationId,
  } = useLoginAccess();

  const onSelectedValueChanged = (e) => {
    setSelectedOrganisationId(e.target.value);
  };

  useEffect(() => {
    if (!loginOrganisationPermissions) {
      return;
    }

    setSelectedOrganisationId(loginOrganisationPermissions[0].organisationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginOrganisationPermissions]);

  let content = <p>&nbsp;</p>;
  if (!loginOrganisationPermissions) {
    content = <p>&nbsp;</p>;
  } else if (loginOrganisationPermissions.length === 0) {
    content = <p>No Access</p>;
  } else if (loginOrganisationPermissions.length === 1) {
    content = (
      <div>
        <Typography
          style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75rem' }}
        >
          Organisation
        </Typography>
        <Typography>
          {loginOrganisationPermissions[0].organisationName}
        </Typography>
      </div>
    );
  } else {
    content = (
      <FormControl>
        <InputLabel id="organisation-select-label">Organisation</InputLabel>
        <Select
          className={classes.select}
          labelId="organisation-select-label"
          value={selectedOrganisationId}
          onChange={onSelectedValueChanged}
        >
          {loginOrganisationPermissions.map((loginOrganisationPermission) => {
            return (
              <MenuItem
                key={loginOrganisationPermission.organisationId}
                value={loginOrganisationPermission.organisationId}
              >
                {loginOrganisationPermission.organisationName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return (
    <List style={{ paddingTop: '0' }}>
      <ListItem style={{ paddingTop: '0' }}>{content}</ListItem>
    </List>
  );
};

export default OrganisationSelector;
