import React, { useState, useEffect } from 'react';

import ContactOverTimeReportsIcon from '@material-ui/icons/SupervisedUserCircle';

import Header from '../../Header';
import AverageContactOverTimeReport from './AverageContactOverTimeReport/AverageContactOverTimeReport';
import ContactPerUserOverTimeReport from './ContactPerUserOverTimeReport/ContactPerUserOverTimeReport';
import { Grid, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { startOfToday, isValid, isAfter } from 'date-fns';

const useStyles = makeStyles({
  padRight: {
    paddingRight: '2rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

const ContactOverTimeReports = () => {
  const classes = useStyles();

  const [selectedFromDate, setSelectedFromDate] = useState(startOfToday());
  const [selectedToDate, setSelectedToDate] = useState(startOfToday());
  const [datesAreValid, setDatesAreValid] = useState(true);

  useEffect(() => {
    if (
      isValid(selectedFromDate) &&
      isValid(selectedToDate) &&
      isAfter(selectedFromDate, selectedToDate)
    ) {
      setDatesAreValid(false);
    } else {
      setDatesAreValid(true);
    }
  }, [selectedFromDate, selectedToDate]);

  return (
    <div>
      <Header
        text="Contact Over Time Reports"
        icon={<ContactOverTimeReportsIcon />}
      ></Header>

      <Grid container className={classes.marginBottom}>
        <Grid item className={classes.padRight} xs={2}>
          <DatePicker
            autoOk
            label="From Date"
            format="d MMM yyyy"
            error={!datesAreValid}
            clearable
            value={selectedFromDate}
            onChange={setSelectedFromDate}
          />
        </Grid>

        <Grid item xs={2}>
          <DatePicker
            autoOk
            label="To Date"
            format="d MMM yyyy"
            error={!datesAreValid}
            clearable
            value={selectedToDate}
            onChange={setSelectedToDate}
          />
        </Grid>
      </Grid>

      <AverageContactOverTimeReport
        selectedFromDate={selectedFromDate}
        selectedToDate={selectedToDate}
      />

      <ContactPerUserOverTimeReport
        selectedFromDate={selectedFromDate}
        selectedToDate={selectedToDate}
      />
    </div>
  );
};

export default ContactOverTimeReports;
