import React, { useRef, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IdleTimer from 'react-idle-timer';

import App from './App';
import { DialogProvider } from '../../providers/DialogProvider';
import { ProgressProvider } from '../../providers/ProgressProvider';
import { SnackbarProvider } from '../../providers/SnackbarProvider';
import { LoginAccessProvider } from '../../providers/LoginAccessProvider';
import { ApiProvider } from '../../providers/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';

const AppContainer = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#0078d4',
      },
      secondary: {
        main: '#0078d4',
      },
    },
  });

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    error,
  } = useAuth0();

  const idleTimerRef = useRef();

  const onIdle = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  useEffect(() => {
    if (error) {
      console.log('Error', error);
    }

    if (isLoading) {
      return;
    }

    if (!error && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error]);

  const autoLogoutTimeoutInMilliseconds = 1000 * 60 * 10; // 10 minutes

  return (
    <div id="app-container">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ProgressProvider>
          <SnackbarProvider>
            <BrowserRouter>
              <DialogProvider>
                <IdleTimer
                  ref={idleTimerRef}
                  element={document}
                  onIdle={onIdle}
                  debounce={1000}
                  timeout={autoLogoutTimeoutInMilliseconds}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <LoginAccessProvider>
                    <ApiProvider>
                      <App />
                    </ApiProvider>
                  </LoginAccessProvider>
                </MuiPickersUtilsProvider>
              </DialogProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </ProgressProvider>
      </ThemeProvider>
    </div>
  );
};

export default AppContainer;
