import React, { useContext, useEffect, useState, createContext } from 'react';

import axios from 'axios';

import { useProgress } from './ProgressProvider';
import { useSnackbar } from './SnackbarProvider';
import { useAuth0 } from '@auth0/auth0-react';

const LoginAccessContext = createContext();
export const useLoginAccess = () => useContext(LoginAccessContext);

export const LoginAccessProvider = ({ children }) => {
  const { showProgress, hideProgress } = useProgress();
  const { showSnackbar } = useSnackbar();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [loginId, setLoginId] = useState();
  const [loginName, setLoginName] = useState();
  const [
    loginOrganisationPermissions,
    setLoginOrganisationPermissions,
  ] = useState();

  const [selectedOrganisationId, setSelectedOrganisationId] = useState('');

  const [
    hasOrganisationPermissions,
    setHasOrganisationPermissions,
  ] = useState();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const fetchLoginOrganisationPermissions = async () => {
      try {
        showProgress();

        let token = await getAccessTokenSilently();

        const response = await axios(
          `${process.env.REACT_APP_API_URL}/api/logins/organisation-permissions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLoginId(response.data.loginId);
        setLoginName(response.data.loginName);
        setLoginOrganisationPermissions(
          response.data.loginOrganisationPermissions.sort((a, b) => {
            if (a.organisationName < b.organisationName) {
              return -1;
            }
            if (a.organisationName > b.organisationName) {
              return 1;
            }
            return 0;
          })
        );
        if (
          response.data.loginOrganisationPermissions &&
          response.data.loginOrganisationPermissions.length > 0
        ) {
          setHasOrganisationPermissions(true);
        } else {
          setHasOrganisationPermissions(false);
        }
      } catch (e) {
        setHasOrganisationPermissions(false);
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchLoginOrganisationPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <LoginAccessContext.Provider
      value={{
        loginId,
        loginName,
        loginOrganisationPermissions,
        selectedOrganisationId,
        setSelectedOrganisationId,
        hasOrganisationPermissions,
      }}
    >
      {children}
    </LoginAccessContext.Provider>
  );
};
