import React, { useState, useEffect } from 'react';

import ManageUsersIcon from '@material-ui/icons/People';
import MaterialTable from 'material-table';

import Header from './Header';
import { useProgress } from '../providers/ProgressProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { TextField } from '@material-ui/core';
import { useApi } from '../providers/ApiProvider';
import { useLoginAccess } from '../providers/LoginAccessProvider';

const ManageUsers = () => {
  const { showProgress, hideProgress } = useProgress();
  const { showSnackbar } = useSnackbar();
  const { selectedOrganisationId } = useLoginAccess();
  const { api } = useApi();

  const [users, setUsers] = useState(null);

  useEffect(() => {
    setUsers(null);
    if (!selectedOrganisationId) {
      return;
    }

    const fetchUsers = async () => {
      try {
        showProgress();

        const usersResponse = await api('/api/users');

        setUsers(usersResponse.data.users);
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId]);

  if (!users) {
    return <p></p>;
  }

  return (
    <div id="manage-users">
      <Header text="Manage Users" icon={<ManageUsersIcon />}></Header>
      <MaterialTable
        title=""
        columns={[
          {
            title: 'Name or Reference',
            field: 'name',
            defaultSort: 'asc',
            editComponent: (props) => {
              return (
                <TextField
                  autoFocus
                  error={props.value !== undefined && props.value.length === 0}
                  value={props.value}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => props.onChange(e.target.value)}
                />
              );
            },
          },
        ]}
        data={users}
        options={{
          addRowPosition: 'first',
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          searchFieldAlignment: 'right',
          emptyRowsWhenPaging: false,
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
        editable={{
          onRowAdd: (newDataRow) => {
            const addUser = async (name) => {
              try {
                showProgress();

                const response = await api('/api/users', 'POST', {
                  name: name,
                });

                const addedUser = response.data.user;

                const updatedUsers = [...users];
                updatedUsers.push({
                  userId: addedUser.userId,
                  name: addedUser.name,
                });
                setUsers(updatedUsers);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            if (newDataRow.name.length === 0) {
              return Promise.reject();
            }

            return addUser(newDataRow.name);
          },
          onRowUpdate: (newDataRow, oldDataRow) => {
            const updateUserName = async (userId, name) => {
              try {
                showProgress();

                await api(`/api/users/${userId}/update-name`, 'PUT', {
                  name: name,
                });

                const updatedUsers = [...users];
                const index = updatedUsers.findIndex(
                  (user) => user.userId === userId
                );
                if (index !== -1) {
                  updatedUsers[index].name = name;
                }
                setUsers(updatedUsers);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            if (newDataRow.name.length === 0) {
              return Promise.reject();
            }

            return updateUserName(newDataRow.userId, newDataRow.name);
          },
          onRowDelete: (oldDataRow) => {
            const deleteUser = async (userId) => {
              try {
                showProgress();

                await api(`/api/users/${userId}`, 'DELETE');

                const updatedUsers = [...users];
                const index = updatedUsers.findIndex(
                  (user) => user.userId === userId
                );
                if (index !== -1) {
                  updatedUsers.splice(index, 1);
                }
                setUsers(updatedUsers);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            return deleteUser(oldDataRow.userId);
          },
        }}
      ></MaterialTable>
    </div>
  );
};

export default ManageUsers;
