import React, { useState, useEffect } from 'react';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import DashboardCard from './DashboardCard';
import { Pie, Line } from 'react-chartjs-2';
import GreenIcon from '@material-ui/icons/CheckCircle';
import AmberIcon from '@material-ui/icons/Warning';
import RedIcon from '@material-ui/icons/Error';
import { format, addDays, startOfToday } from 'date-fns';

import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useLoginAccess } from '../../../../providers/LoginAccessProvider';
import { useApi } from '../../../../providers/ApiProvider';
import CenteredProgress from '../../../CenteredProgress';

const getUsersCount = (type, selectedDate, reportData) => {
  const usersCount =
    reportData[format(selectedDate, 'yyyy-MM-dd')][`${type}UsersCount`];

  return usersCount;
};

const getUsersPercentage = (type, selectedDate, reportData) => {
  const usersCount = getUsersCount(type, selectedDate, reportData);

  const totalUsersCount =
    reportData[format(selectedDate, 'yyyy-MM-dd')][`totalUsersCount`];

  if (usersCount === 0) {
    return '0%';
  }

  return `${Math.round((100 * usersCount) / totalUsersCount)}%`;
};

const green = '#4da750';
const amber = '#fc9423';
const red = '#e73e3a';

const useStyles = makeStyles({
  marginRightLarge: {
    marginRight: '2rem',
  },
  marginRightSmall: {
    marginRight: '1rem',
  },
  marginBottom: {
    marginBottom: '2rem',
  },
  green: {
    color: green,
  },
  amber: {
    color: amber,
  },
  red: {
    color: red,
  },
  loader: {
    position: 'relative',
    left: '50%',
    top: '50%',
  },
});

const DashboardGreenAmberRedReports = () => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const { selectedOrganisationId } = useLoginAccess();
  const { api } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(startOfToday());

  const onDateChanged = (selectedDate) => {
    setSelectedDate(selectedDate);
  };

  const [userData, setUserData] = useState({
    green: {
      count: 0,
      percentage: 0,
    },
    amber: {
      count: 0,
      percentage: 0,
    },
    red: {
      count: 0,
      percentage: 0,
    },
  });

  const [pieData, setPieData] = useState([0, 0, 0]);

  const [lineLabels, setLineLabels] = useState(
    [
      addDays(startOfToday(), -7),
      addDays(startOfToday(), -6),
      addDays(startOfToday(), -5),
      addDays(startOfToday(), -4),
      addDays(startOfToday(), -3),
      addDays(startOfToday(), -2),
      addDays(startOfToday(), -1),
      addDays(startOfToday(), -0),
    ].map((date) => format(new Date(date), 'do MMMM'))
  );
  const [lineGreenData, setLineGreenData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [lineAmberData, setLineAmberData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [lineRedData, setLineRedData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (!selectedOrganisationId) {
      return;
    }

    const fetchContactEvents = async () => {
      try {
        setIsLoading(true);

        const queryString = `?from=${format(
          addDays(selectedDate, -7),
          'yyyy-MM-dd'
        )}&to=${format(selectedDate, 'yyyy-MM-dd')}`;

        const response = await api(
          `/api/reports/green-amber-red${queryString}`
        );

        const reportData = response.data.greenAmberRedReportData;

        const userData = {
          green: {
            count: getUsersCount('green', selectedDate, reportData),
            percentage: getUsersPercentage('green', selectedDate, reportData),
          },
          amber: {
            count: getUsersCount('amber', selectedDate, reportData),
            percentage: getUsersPercentage('amber', selectedDate, reportData),
          },
          red: {
            count: getUsersCount('red', selectedDate, reportData),
            percentage: getUsersPercentage('red', selectedDate, reportData),
          },
        };

        setUserData(userData);

        setPieData([
          userData['green'].count,
          userData['amber'].count,
          userData['red'].count,
        ]);

        setLineLabels(
          Object.keys(reportData).map((date) =>
            format(new Date(date), 'do MMMM')
          )
        );

        setLineGreenData(
          Object.values(reportData).map((date) => date.greenUsersCount)
        );

        setLineAmberData(
          Object.values(reportData).map((date) => date.amberUsersCount)
        );

        setLineRedData(
          Object.values(reportData).map((date) => date.redUsersCount)
        );
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedOrganisationId]);

  return (
    <>
      <CenteredProgress isLoading={isLoading}>
        <Grid container className={classes.marginBottom}>
          <Grid item className={classes.marginRightLarge}>
            <DatePicker
              autoOk
              label="Date"
              format="d MMM yyyy"
              value={selectedDate}
              onChange={onDateChanged}
            />
          </Grid>
        </Grid>

        {userData && (
          <>
            <Grid container spacing={2}>
              <DashboardCard
                width={4}
                icon={<GreenIcon />}
                color="#4da750"
                title={`Green Users on ${format(selectedDate, 'do MMMM')}`}
                subtitle="All contact events less than 8 minutes (or no contact events)"
                counter={userData['green'].count}
                subCounter={userData['green'].percentage}
              />

              <DashboardCard
                width={4}
                icon={<AmberIcon />}
                color="#fc9423"
                title={`Amber Users on ${format(selectedDate, 'do MMMM')}`}
                subtitle="At least one contact event longer than 8 minutes"
                counter={userData['amber'].count}
                subCounter={userData['amber'].percentage}
              />

              <DashboardCard
                width={4}
                icon={<RedIcon />}
                color="#e73e3a"
                title={`Red Users on ${format(selectedDate, 'do MMMM')}`}
                subtitle="At least one contact event longer than 15 minutes"
                counter={userData['red'].count}
                subCounter={userData['red'].percentage}
              />
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              <Grid item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      Green, Amber and Red Users on{' '}
                      {format(selectedDate, 'do MMMM')}
                    </Typography>

                    <div style={{ position: 'relative', height: '300px' }}>
                      <Pie
                        data={{
                          labels: ['Green Users', 'Amber Users', 'Red Users'],
                          datasets: [
                            {
                              data: pieData,
                              backgroundColor: [green, amber, red],
                            },
                          ],
                        }}
                        options={{ maintainAspectRatio: false }}
                      ></Pie>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={8}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      Green, Amber and Red Users from{' '}
                      {format(addDays(selectedDate, -7), 'do MMMM')} to{' '}
                      {format(selectedDate, 'do MMMM')}
                    </Typography>

                    <div style={{ position: 'relative', height: '300px' }}>
                      <Line
                        data={{
                          labels: lineLabels,
                          datasets: [
                            {
                              label: 'Green Users',
                              backgroundColor: green,
                              borderColor: green,
                              cubicInterpolationMode: 'monotone',
                              fill: false,
                              data: lineGreenData,
                            },
                            {
                              label: 'Amber Users',
                              backgroundColor: amber,
                              borderColor: amber,
                              cubicInterpolationMode: 'monotone',
                              fill: false,
                              data: lineAmberData,
                            },
                            {
                              label: 'Red Users',
                              backgroundColor: red,
                              borderColor: red,
                              cubicInterpolationMode: 'monotone',
                              fill: false,
                              data: lineRedData,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: { yAxes: [{ ticks: { min: 0 } }] },
                        }}
                      ></Line>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </CenteredProgress>
    </>
  );
};

export default DashboardGreenAmberRedReports;
