import React from 'react';

import {
  makeStyles,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  ListItemText,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContactReportsIcon from '@material-ui/icons/SupervisedUserCircle';
import TracingReportsIcon from '@material-ui/icons/AssessmentOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import ManageUsersIcon from '@material-ui/icons/People';
import ManageDevicesIcon from '@material-ui/icons/SpeakerPhone';
import ManageDeviceAssignmentsIcon from '@material-ui/icons/ContactsOutlined';

import AppDrawerLink from '../AppDrawerLink';
import { appRoutes } from '../../common/appRoutes';
import OrganisationSelector from '../OrganisationSelector';
import { useAuth0 } from '@auth0/auth0-react';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));

const AppDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const classes = useStyles();

  const { logout } = useAuth0();

  const isPermanentDrawer = useMediaQuery((theme) =>
    theme.breakpoints.up('md')
  );

  const onDrawerClick = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onLogoutClick = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      variant={isPermanentDrawer ? 'permanent' : 'temporary'}
      open={isDrawerOpen}
      keepMounted
      onClose={onDrawerClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {isPermanentDrawer && <Toolbar />}

      <div className={classes.drawerContainer} onClick={onDrawerClick}>
        <OrganisationSelector />

        <Divider />

        <List>
          <AppDrawerLink
            icon={<DashboardIcon />}
            text="Dashboard"
            target={appRoutes.dashboard}
          ></AppDrawerLink>

          <AppDrawerLink
            icon={<ContactReportsIcon />}
            text="Contact Reports"
            target={appRoutes.contactReports}
          ></AppDrawerLink>

          <AppDrawerLink
            icon={<TracingReportsIcon />}
            text="Tracing Report"
            target={appRoutes.reportsContactTracing}
          ></AppDrawerLink>

          <AppDrawerLink
            icon={<ManageUsersIcon />}
            text="Manage Users"
            target={appRoutes.manageUsers}
          ></AppDrawerLink>

          <AppDrawerLink
            icon={<ManageDevicesIcon />}
            text="Manage Devices"
            target={appRoutes.viewDevices}
          ></AppDrawerLink>

          <AppDrawerLink
            icon={<ManageDeviceAssignmentsIcon />}
            text="Device Assignments"
            target={appRoutes.manageDeviceAssignments}
          ></AppDrawerLink>
        </List>

        <Divider />

        <List>
          <ListItem button onClick={onLogoutClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
