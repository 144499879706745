import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';

import Header from '../../Header';
import DashboardGreenAmberRedReports from './GreenAmberRedReport/DashboardGreenAmberRedReports';

const Dashboard = () => {
  return (
    <div>
      <Header text="Dashboard" icon={<DashboardIcon />}></Header>

      <DashboardGreenAmberRedReports />
    </div>
  );
};

export default Dashboard;
