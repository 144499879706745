import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  loader: {
    position: 'relative',
    left: '50%',
    top: '50%',
  },
});

const CenteredProgress = ({ children, isLoading }) => {
  const classes = useStyles();

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={classes.container}
        style={{ display: isLoading ? 'block' : 'none' }}
      >
        <CircularProgress className={classes.loader} />
      </div>
      <div
        style={{
          opacity: isLoading ? 0.3 : 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CenteredProgress;
