import React, { useState, useEffect } from 'react';

import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import ViewDevicesIcon from '@material-ui/icons/SpeakerPhone';
import { format } from 'date-fns';

import Header from './Header';
import { useProgress } from '../providers/ProgressProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { useApi } from '../providers/ApiProvider';
import { useLoginAccess } from '../providers/LoginAccessProvider';

const ManageDevices = () => {
  const { showProgress, hideProgress } = useProgress();
  const { showSnackbar } = useSnackbar();
  const { selectedOrganisationId } = useLoginAccess();
  const { api } = useApi();

  const [devices, setDevices] = useState();

  useEffect(() => {
    setDevices(null);
    if (!selectedOrganisationId) {
      return;
    }

    const fetchDevices = async () => {
      try {
        showProgress();

        const usersResponse = await api('/api/devices');

        setDevices(usersResponse.data.devices);
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId]);

  if (!devices) {
    return <p></p>;
  }

  return (
    <div id="manage-devices">
      <Header text="Manage Devices" icon={<ViewDevicesIcon />}></Header>
      <MaterialTable
        title=""
        columns={[
          {
            title: 'Device Label',
            field: 'userLabel',
            defaultSort: 'asc',
            editComponent: (props) => {
              return (
                <TextField
                  autoFocus
                  error={props.value !== undefined && props.value.length === 0}
                  value={props.value}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => props.onChange(e.target.value)}
                />
              );
            },
            editable: 'onUpdate',
          },
          { title: 'Hardware Id', field: 'hardwareId', editable: 'never' },
          {
            title: 'Last Recorded Event Date Time',
            render: (rowData) =>
              rowData.lastEventEndDateTime
                ? format(new Date(rowData.lastEventEndDateTime), 'd MMM HH:mm')
                : 'Not Available',
            customSort: (a, b) => {
              a = a.lastEventEndDateTime;
              b = b.lastEventEndDateTime;
              if (!a) a = '';
              if (!b) b = '';
              return a.localeCompare(b);
            },
            editable: 'never',
          },
          {
            title: 'Last Communication Date Time',
            render: (rowData) =>
              rowData.lastEventApiReceivedDateTime
                ? format(
                    new Date(rowData.lastEventApiReceivedDateTime),
                    'd MMM HH:mm'
                  )
                : 'Not Available',
            customSort: (a, b) => {
              a = a.lastEventApiReceivedDateTime;
              b = b.lastEventApiReceivedDateTime;
              if (!a) a = '';
              if (!b) b = '';
              return a.localeCompare(b);
            },
            editable: 'never',
          },
          {
            title: 'Last Reported Battery Level',
            field: 'lastEventBatteryLevel',
            editable: 'never',
          },
        ]}
        data={devices}
        editable={{
          onRowUpdate: (newDataRow) => {
            const updateDeviceLabel = async (deviceId, label) => {
              try {
                showProgress();

                await api(`/api/devices/${deviceId}/update-label`, 'PUT', {
                  label: label,
                });

                const updatedDevices = [...devices];
                const deviceIndex = updatedDevices.findIndex(
                  (device) => device.deviceId === deviceId
                );
                if (deviceIndex !== -1) {
                  updatedDevices[deviceIndex].userLabel = label;
                }
                setDevices(updatedDevices);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            if (newDataRow.userLabel.length === 0) {
              return Promise.reject();
            }

            return updateDeviceLabel(newDataRow.deviceId, newDataRow.userLabel);
          },
        }}
        options={{
          addRowPosition: 'first',
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          searchFieldAlignment: 'right',
          emptyRowsWhenPaging: false,
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      ></MaterialTable>
    </div>
  );
};

export default ManageDevices;
