import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { toHHMMSS } from '../../../common/utils';

const ContactTracingReportContactSummaryGrid = ({
  selectedUserName,
  contactSummaries,
}) => {
  return (
    <div id="contact-tracing-report-event-details">
      {contactSummaries && contactSummaries.length === 0 ? (
        <p>No contact events found.</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Selected User</TableCell>
                <TableCell>Contact User</TableCell>
                <TableCell>Total Contact Duration (HH:MM:SS)</TableCell>
                <TableCell>Total Contact Events</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactSummaries?.map((contactSummary, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{selectedUserName}</TableCell>
                    <TableCell>{contactSummary.detectedUserName}</TableCell>
                    <TableCell>
                      {toHHMMSS(contactSummary.totalDurationInSeconds)}
                    </TableCell>
                    <TableCell>{contactSummary.totalNumberOfEvents}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ContactTracingReportContactSummaryGrid;
