import React from 'react';

import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    color: '#FFFFFF',
  },
}));

const TopAppBar = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const classes = useStyles();

  const { user } = useAuth0();

  const hideMenuButton = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const onMenuButtonClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense" disableGutters={!hideMenuButton}>
        {!hideMenuButton && (
          <IconButton
            className={classes.menuButton}
            onClick={onMenuButtonClick}
          >
            <MenuIcon />
          </IconButton>
        )}

        <img
          style={{ height: '2.2rem' }}
          src="/img/rt-smart-data-logo-white-925-229.png"
          alt="RT Smart Data Logo"
        ></img>

        <Typography
          style={{ marginBottom: '0.1rem' }}
          variant="h6"
          className={classes.title}
        >
          {`Smart Data`}
        </Typography>
        <Typography>{user?.name}</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
