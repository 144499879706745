import React, { useState, useEffect } from 'react';

import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import MaterialTable from 'material-table';
import { format, isAfter, isValid, parseISO, isDate } from 'date-fns';
import DeviceAssignmentsIcon from '@material-ui/icons/ContactsOutlined';

import Header from './Header';
import { useProgress } from '../providers/ProgressProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { useApi } from '../providers/ApiProvider';
import { useLoginAccess } from '../providers/LoginAccessProvider';

const useStyles = makeStyles({
  select: {
    minWidth: '5rem',
  },
});

const sortByProperty = (property) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const sortByProperties = (...args) => {
  var props = args;
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    while (result === 0 && i < numberOfProperties) {
      result = sortByProperty(props[i])(obj1, obj2);
      i++;
    }
    return result;
  };
};

const ManageDeviceAssignments = () => {
  const classes = useStyles();

  const { showProgress, hideProgress } = useProgress();
  const { showSnackbar } = useSnackbar();
  const { selectedOrganisationId } = useLoginAccess();
  const { api } = useApi();

  const [deviceAssignments, setDeviceAssignments] = useState();
  const [users, setUsers] = useState();
  const [devices, setDevices] = useState();

  const [userSelectError, setUserSelectError] = useState(false);
  const [deviceSelectError, setDeviceSelectError] = useState(false);
  const [assignmentStartError, setAssignmentStartError] = useState(false);
  const [assignmentEndError, setAssignmentEndError] = useState(false);

  useEffect(() => {
    setDeviceAssignments(null);
    setUsers(null);
    setDevices(null);
    if (!selectedOrganisationId) {
      return;
    }

    const fetchDeviceAssignments = async () => {
      try {
        showProgress();
        let response = await api('/api/users/device-assignments');

        response.data.deviceAssignments.forEach((deviceAssignment) => {
          deviceAssignment.assignmentStart = parseISO(
            deviceAssignment.assignmentStart
          );
          deviceAssignment.assignmentEnd = parseISO(
            deviceAssignment.assignmentEnd
          );
          deviceAssignment.device = `${deviceAssignment.deviceLabel} (${deviceAssignment.deviceHardwareId})`;
        });

        setDeviceAssignments(
          response.data.deviceAssignments.sort(
            sortByProperties('userName', 'assignmentStart')
          )
        );

        response = await api('/api/users');

        setUsers(response.data.users);

        response = await api('/api/devices');

        setDevices(response.data.devices);
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchDeviceAssignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId]);

  const validateRow = (newDataRow) => {
    if (
      isValid(newDataRow.assignmentStart) &&
      isValid(newDataRow.assignmentEnd) &&
      isAfter(newDataRow.assignmentStart, newDataRow.assignmentEnd)
    ) {
      showSnackbar('Assignment End must be empty or after Assignment Start');
      setAssignmentEndError(true);
      return false;
    }

    if (
      !newDataRow.userId ||
      !newDataRow.deviceId ||
      !newDataRow.assignmentStart
    ) {
      if (!newDataRow.userId) {
        setUserSelectError(true);
      }

      if (!newDataRow.deviceId) {
        setDeviceSelectError(true);
      }

      if (!isValid(newDataRow.assignmentStart)) {
        setAssignmentStartError(true);
      }

      showSnackbar('User, Device and Assignment Start are required.');
      return false;
    }
    return true;
  };

  if (!deviceAssignments || !users || !devices) {
    return <p></p>;
  }

  const usersList = users.map((user) => (
    <MenuItem key={user.userId} value={user.userId}>
      {user.name}
    </MenuItem>
  ));

  const devicesList = devices.map((device) => (
    <MenuItem key={device.deviceId} value={device.deviceId}>
      {`${device.userLabel} (${device.hardwareId})`}
    </MenuItem>
  ));

  return (
    <div id="manage-device-assignments">
      <Header
        text="Manage Device Assignments"
        icon={<DeviceAssignmentsIcon />}
      ></Header>
      <MaterialTable
        title=""
        columns={[
          {
            title: 'User',
            field: 'userId',
            editable: 'always',
            render: (data) => {
              return (
                <span>
                  {users.find((user) => user.userId === data.userId).name}
                </span>
              );
            },
            editComponent: (props) => {
              return (
                <FormControl className={classes.select} error={userSelectError}>
                  <Select
                    value={props.value === undefined ? '' : props.value}
                    onChange={(e) => {
                      setUserSelectError(false);
                      props.onChange(e.target.value);
                    }}
                  >
                    {usersList}
                  </Select>
                </FormControl>
              );
            },
          },
          {
            title: 'Device',
            field: 'deviceId',
            editable: 'always',
            render: (data) => {
              const device = devices.find(
                (device) => device.deviceId === data.deviceId
              );
              return (
                <span>{`${device.userLabel} (${device.hardwareId})`}</span>
              );
            },
            editComponent: (props) => {
              return (
                <FormControl
                  className={classes.select}
                  error={deviceSelectError}
                >
                  <Select
                    value={props.value === undefined ? '' : props.value}
                    onChange={(e) => {
                      setDeviceSelectError(false);
                      props.onChange(e.target.value);
                    }}
                  >
                    {devicesList}
                  </Select>
                </FormControl>
              );
            },
          },
          {
            title: 'Assignment Start Date Time',
            field: 'assignmentStart',
            type: 'datetime',
            editable: 'always',
            render: (rowData) => {
              return isValid(rowData.assignmentStart)
                ? format(rowData.assignmentStart, 'd MMM HH:mm')
                : 'Not Set';
            },
            editComponent: (props) => {
              let value;
              if (isDate(props.value)) {
                value = props.value;
              } else {
                value = parseISO(props.value);
              }

              return (
                <DateTimePicker
                  autoOk
                  clearable
                  ampm={false}
                  value={isValid(value) ? value : null}
                  onChange={(e) => {
                    if (e === null) {
                      setAssignmentStartError(true);
                    } else {
                      setAssignmentStartError(false);
                    }
                    props.onChange(e);
                  }}
                  format="d MMM HH:mm"
                  error={assignmentStartError}
                />
              );
            },
          },
          {
            title: 'Assignment End Date Time',
            field: 'assignmentEnd',
            type: 'datetime',
            editable: 'always',
            render: (rowData) => {
              return isValid(rowData.assignmentEnd)
                ? format(rowData.assignmentEnd, 'd MMM HH:mm')
                : 'Not Set';
            },
            editComponent: (props) => {
              let value;
              if (isDate(props.value)) {
                value = props.value;
              } else {
                value = parseISO(props.value);
              }

              return (
                <DateTimePicker
                  autoOk
                  clearable
                  ampm={false}
                  value={isValid(value) ? value : null}
                  onChange={(e) => {
                    setAssignmentEndError(false);
                    props.onChange(e);
                  }}
                  format="d MMM HH:mm"
                  error={assignmentEndError}
                />
              );
            },
          },
        ]}
        data={deviceAssignments}
        editable={{
          onRowAdd: (newDataRow) => {
            const addDeviceAssignment = async (
              userId,
              deviceId,
              assignmentStart,
              assignmentEnd
            ) => {
              try {
                showProgress();

                const response = await api(
                  '/api/users/device-assignments',
                  'POST',
                  {
                    userId: userId,
                    deviceId: deviceId,
                    assignmentStart: assignmentStart,
                    assignmentEnd: assignmentEnd,
                  }
                );

                const addedDeviceAssignment = response.data.deviceAssignment;

                const updatedDeviceAssignments = [...deviceAssignments];
                updatedDeviceAssignments.push({
                  deviceAssignmentId: addedDeviceAssignment.deviceAssignmentId,
                  userId: addedDeviceAssignment.userId,
                  userName: addedDeviceAssignment.userName,
                  deviceId: addedDeviceAssignment.deviceId,
                  deviceHardwareId: addedDeviceAssignment.deviceHardwareId,
                  deviceLabel: addedDeviceAssignment.deviceLabel,
                  assignmentStart: parseISO(
                    addedDeviceAssignment.assignmentStart
                  ),
                  assignmentEnd: parseISO(addedDeviceAssignment.assignmentEnd),
                  device: `${addedDeviceAssignment.deviceLabel} (${addedDeviceAssignment.deviceHardwareId})`,
                });
                setDeviceAssignments(
                  updatedDeviceAssignments.sort(
                    sortByProperties('userName', 'assignmentStart')
                  )
                );

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            if (!isDate(newDataRow.assignmentStart)) {
              newDataRow.assignmentStart = parseISO(newDataRow.assignmentStart);
            }

            if (!isDate(newDataRow.assignmentEnd)) {
              newDataRow.assignmentEnd = parseISO(newDataRow.assignmentEnd);
            }

            if (!validateRow(newDataRow)) {
              return Promise.reject();
            }

            return addDeviceAssignment(
              newDataRow.userId,
              newDataRow.deviceId,
              newDataRow.assignmentStart,
              newDataRow.assignmentEnd
            );
          },
          onRowUpdate: (newDataRow) => {
            const updateDeviceAssignment = async (
              deviceAssignmentId,
              userId,
              deviceId,
              assignmentStart,
              assignmentEnd
            ) => {
              try {
                showProgress();

                await api(
                  `/api/users/device-assignments/${deviceAssignmentId}`,
                  'PUT',
                  {
                    deviceAssignmentId: deviceAssignmentId,
                    userId: userId,
                    deviceId: deviceId,
                    assignmentStart: assignmentStart,
                    assignmentEnd: assignmentEnd,
                  }
                );

                const user = users.find((user) => user.userId === userId);
                const device = devices.find(
                  (device) => device.deviceId === deviceId
                );

                const updatedDeviceAssignments = [...deviceAssignments];
                const index = updatedDeviceAssignments.findIndex(
                  (assignment) =>
                    assignment.deviceAssignmentId === deviceAssignmentId
                );
                if (index !== -1) {
                  updatedDeviceAssignments[index].userId = user.userId;
                  updatedDeviceAssignments[index].userName = user.name;
                  updatedDeviceAssignments[index].deviceId = device.deviceId;
                  updatedDeviceAssignments[index].deviceHardwareId =
                    device.hardwareId;
                  updatedDeviceAssignments[index].deviceLabel =
                    device.userLabel;
                  updatedDeviceAssignments[
                    index
                  ].assignmentStart = assignmentStart;
                  updatedDeviceAssignments[index].assignmentEnd = assignmentEnd;
                }
                setDeviceAssignments(updatedDeviceAssignments);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            if (!isDate(newDataRow.assignmentStart)) {
              newDataRow.assignmentStart = parseISO(newDataRow.assignmentStart);
            }

            if (!isDate(newDataRow.assignmentEnd)) {
              newDataRow.assignmentEnd = parseISO(newDataRow.assignmentEnd);
            }

            if (!validateRow(newDataRow)) {
              return Promise.reject();
            }

            return updateDeviceAssignment(
              newDataRow.deviceAssignmentId,
              newDataRow.userId,
              newDataRow.deviceId,
              newDataRow.assignmentStart,
              newDataRow.assignmentEnd
            );
          },
          onRowDelete: (oldDataRow) => {
            const deleteDeviceAssignment = async (deviceAssignmentId) => {
              try {
                showProgress();

                await api(
                  `/api/users/device-assignments/${deviceAssignmentId}`,
                  'DELETE'
                );

                const updatedDeviceAssignments = [...deviceAssignments];
                const index = updatedDeviceAssignments.findIndex(
                  (assignment) =>
                    assignment.deviceAssignmentId === deviceAssignmentId
                );
                if (index !== -1) {
                  updatedDeviceAssignments.splice(index, 1);
                }
                setDeviceAssignments(updatedDeviceAssignments);

                return Promise.resolve();
              } catch (e) {
                showSnackbar('Server connection error.');
                return Promise.reject();
              } finally {
                hideProgress();
              }
            };

            return deleteDeviceAssignment(oldDataRow.deviceAssignmentId);
          },
        }}
        options={{
          addRowPosition: 'first',
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          searchFieldAlignment: 'right',
          emptyRowsWhenPaging: false,
          sorting: false,
          search: false,
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      ></MaterialTable>
    </div>
  );
};

export default ManageDeviceAssignments;
