import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dropdown: {
    marginBottom: '0.5rem',
    minWidth: 150,
  },
});

const UserSelect = ({ users, selectedUserId, onSelectedUserIdChanged }) => {
  const classes = useStyles();

  const onChange = (event) => {
    onSelectedUserIdChanged(event.target.value);
  };

  const usersList = users.map((user) => (
    <MenuItem key={user.userId} value={user.userId}>
      {user.name}
    </MenuItem>
  ));

  return (
    <FormControl className={classes.dropdown}>
      <InputLabel id="user-select-label">User</InputLabel>
      <Select
        labelId="user-select-label"
        value={selectedUserId}
        onChange={onChange}
      >
        {usersList}
      </Select>
    </FormControl>
  );
};

export default UserSelect;
