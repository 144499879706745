import React, { useRef, useEffect, useState } from 'react';

import { Paper } from '@material-ui/core';
import ForceGraph2D from 'react-force-graph-2d';

import { toHHMMSS } from '../../../common/utils';

const getNodes = (selectedUserName, contactSummaries) => {
  const nodes = [];

  if (!selectedUserName || !contactSummaries || contactSummaries.length === 0) {
    return nodes;
  }

  nodes.push({
    id: `${selectedUserName}`,
    tooltip: `Contact Tracing Report Selected User: ${selectedUserName}`,
  });

  for (const contactSummary of contactSummaries) {
    const totalEvents = contactSummary.totalNumberOfEvents;
    const totalSeconds = contactSummary.totalDurationInSeconds;

    nodes.push({
      id: `${contactSummary.detectedUserName}`,
      tooltip: `Contact with ${
        contactSummary.detectedUserName
      } for a total of ${toHHMMSS(
        totalSeconds
      )} (HH:MM:SS) - ${totalEvents} total contact events`,
    });
  }

  return nodes;
};

const getLinks = (nodes) => {
  const links = [];

  for (let nodeIndex = 1; nodeIndex < nodes.length; nodeIndex++) {
    const targetNode = nodes[nodeIndex];
    links.push({
      source: nodes[0].id,
      target: targetNode.id,
      tooltip: targetNode.tooltip,
    });
  }
  return links;
};

const getLinkColor = () => {
  return 'rgb(217, 33, 32)';
};

const getPersonIcon = () => {
  const image = new Image();
  image.src = '/img/person-icon.png';
  return image;
};

const ContactTracingReportContactSummaryForceGraph = ({
  selectedUserName,
  contactSummaries,
}) => {
  const forceGraphRef = useRef();

  const [forceGraphWidth, setForceGraphWidth] = useState(100);
  const [forceGraphHeight, setForceGraphHeight] = useState(100);

  const [nodes, setNodes] = useState();
  const [links, setLinks] = useState();

  const [personIcon] = useState(getPersonIcon());

  useEffect(() => {
    if (
      !selectedUserName ||
      !contactSummaries ||
      contactSummaries.length === 0
    ) {
      return;
    }

    const nodes = getNodes(selectedUserName, contactSummaries);
    const links = getLinks(nodes);

    setNodes(nodes);
    setLinks(links);

    setTimeout(() => {
      if (forceGraphRef.current) forceGraphRef.current.zoom(2.5);

      const forceGraphContainer = document.querySelector(
        '#force-graph-container'
      );
      if (forceGraphContainer) {
        setForceGraphWidth(forceGraphContainer.offsetWidth);
        setForceGraphHeight(forceGraphContainer.offsetHeight);
      }
    }, 10);
  }, [selectedUserName, contactSummaries]);

  if (!contactSummaries) {
    return <p></p>;
  }

  if (
    contactSummaries.length === 0 ||
    !nodes ||
    nodes.length === 0 ||
    !links ||
    links.length === 0
  ) {
    return <p>No contact events found.</p>;
  }

  return (
    <Paper id="force-graph-container" style={{ height: '20rem' }}>
      <ForceGraph2D
        ref={forceGraphRef}
        width={forceGraphWidth}
        height={forceGraphHeight}
        graphData={{ nodes: nodes, links: links }}
        nodeLabel="tooltip"
        linkLabel="tooltip"
        linkColor={getLinkColor}
        linkWidth={3}
        nodeCanvasObject={(node, canvas, globalScale) => {
          const fontSize = 14 / globalScale;
          canvas.font = `${fontSize}px Roboto`;
          canvas.fillText(node.id, node.x + 3, node.y - 3);
          canvas.drawImage(personIcon, node.x - 5, node.y - 5, 10, 10);
        }}
      ></ForceGraph2D>
    </Paper>
  );
};

export default ContactTracingReportContactSummaryForceGraph;
