import React, { useState, useEffect } from 'react';

import { withRouter, Redirect, Link, Route, Switch } from 'react-router-dom';
import { makeStyles, Paper, Tabs, Tab, Grid } from '@material-ui/core';
import ReportsIcon from '@material-ui/icons/AssessmentOutlined';

import Header from '../../Header';
import { useProgress } from '../../../providers/ProgressProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import UserSelect from '../../UserSelect';
import ContactTracingReportContactDetailsGrid from './ContactTracingReportContactDetailsGrid';
import ContactTracingReportContactSummaryGrid from './ContactTracingReportContactSummaryGrid';
import { appRoutes } from '../../../common/appRoutes';
import ContactTracingReportContactSummaryChart from './ContactTracingReportContactSummaryChart';
import ContactTracingReportContactSummaryForceGraph from './ContactTracingReportContactSummaryForceGraph';
import { DatePicker } from '@material-ui/pickers';
import { addDays, startOfToday, isValid, format, isAfter } from 'date-fns';
import { useApi } from '../../../providers/ApiProvider';
import { useLoginAccess } from '../../../providers/LoginAccessProvider';

const useStyles = makeStyles({
  padRight: {
    paddingRight: '2rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

const ContactTracingReportPage = withRouter(({ location }) => {
  const classes = useStyles();

  const { showProgress, hideProgress } = useProgress();
  const { showSnackbar } = useSnackbar();
  const { selectedOrganisationId } = useLoginAccess();
  const { api } = useApi();

  const [users, setUsers] = useState([]);
  const [contactEvents, setContactEvents] = useState(null);
  const [contactSummaries, setContactSummaries] = useState(null);

  const [selectedUser, setSelectedUser] = useState();

  const onSelectedUserIdChanged = (userId) => {
    const user = users.find((user) => user.userId === userId);
    setSelectedUser(user);
  };

  const [selectedFromDate, setSelectedFromDate] = useState(
    addDays(startOfToday(), -7)
  );

  const onFromDateChanged = (selectedDate) => {
    setSelectedFromDate(selectedDate);
  };

  const [selectedToDate, setSelectedToDate] = useState(startOfToday());

  const onToDateChanged = (selectedDate) => {
    setSelectedToDate(selectedDate);
  };

  const [datesAreValid, setDatesAreValid] = useState(true);

  useEffect(() => {
    setUsers([]);
    setSelectedUser(null);
    if (!selectedOrganisationId) {
      return;
    }

    const fetchUsers = async () => {
      try {
        showProgress();

        const response = await api('/api/users');

        setUsers(response.data.users);
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId]);

  useEffect(() => {
    if (
      isValid(selectedFromDate) &&
      isValid(selectedToDate) &&
      isAfter(selectedFromDate, selectedToDate)
    ) {
      setDatesAreValid(false);
    } else {
      setDatesAreValid(true);
    }

    if (!selectedUser || !selectedUser.userId) {
      return;
    }

    const fetchContactEventsAndSummaries = async () => {
      try {
        setContactEvents(null);
        setContactSummaries(null);

        showProgress();

        let queryString = '';
        if (isValid(selectedFromDate) || isValid(selectedToDate)) {
          queryString += '?';

          if (isValid(selectedFromDate)) {
            queryString += 'from=';
            queryString += format(selectedFromDate, 'yyyy-MM-dd');
          }

          if (isValid(selectedToDate)) {
            if (queryString.length > 1) {
              queryString += '&';
            }

            queryString += 'to=';
            queryString += format(selectedToDate, 'yyyy-MM-dd');
          }
        }

        const contactEventsResponse = await api(
          `/api/users/${selectedUser.userId}/contact-events${queryString}`
        );

        contactEventsResponse.data.contactTracingReportContactEvents.forEach(
          (event) => {
            event.start = new Date(event.start);
            event.end = new Date(event.end);
          }
        );

        setContactEvents(
          contactEventsResponse.data.contactTracingReportContactEvents
        );

        let contactSummariesResponse = await api(
          `/api/users/${selectedUser.userId}/contact-summaries${queryString}`
        );

        setContactSummaries(
          contactSummariesResponse.data.contactTracingReportContactSummaries
        );
      } catch (e) {
        showSnackbar('Server connection error.');
      } finally {
        hideProgress();
      }
    };

    fetchContactEventsAndSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, selectedFromDate, selectedToDate]);

  if (location.pathname === appRoutes.reportsContactTracing) {
    return (
      <Redirect
        exact
        from={appRoutes.reportsContactTracing}
        to={appRoutes.reportsContactTracingContactSummary}
      />
    );
  }

  return (
    <div id="contact-tracing-report-page">
      <Header text="Contact Tracing Report" icon={<ReportsIcon />}></Header>

      <Grid container className={classes.marginBottom}>
        <Grid item className={classes.padRight}>
          <UserSelect
            users={users}
            selectedUserId={
              selectedUser && selectedUser.userId ? selectedUser.userId : ''
            }
            onSelectedUserIdChanged={onSelectedUserIdChanged}
          />
        </Grid>
        <Grid item className={classes.padRight}>
          <DatePicker
            autoOk
            label="From Date"
            format="d MMM yyyy"
            error={!datesAreValid}
            clearable
            value={selectedFromDate}
            onChange={onFromDateChanged}
          />
        </Grid>
        <Grid item>
          <DatePicker
            autoOk
            label="To Date"
            format="d MMM yyyy"
            error={!datesAreValid}
            clearable
            value={selectedToDate}
            onChange={onToDateChanged}
          />
        </Grid>
      </Grid>

      <div className={classes.userSelect}></div>

      {users && users.length > 0 && selectedUser && (
        <div id="contact-tracing-tabs-and-content-container">
          <Paper square className={classes.marginBottom}>
            <Tabs
              value={location.pathname}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Contact Summary"
                value={appRoutes.reportsContactTracingContactSummary}
                component={Link}
                to={appRoutes.reportsContactTracingContactSummary}
              />

              <Tab
                label="Contact Summary Chart"
                value={appRoutes.reportsContactTracingContactSummaryChart}
                component={Link}
                to={appRoutes.reportsContactTracingContactSummaryChart}
              />

              <Tab
                label="Contact Summary Grid"
                value={appRoutes.reportsContactTracingContactSummaryGrid}
                component={Link}
                to={appRoutes.reportsContactTracingContactSummaryGrid}
              />

              <Tab
                label="Contact Details Grid"
                value={appRoutes.reportsContactTracingContactDetailsGrid}
                component={Link}
                to={appRoutes.reportsContactTracingContactDetailsGrid}
              />
            </Tabs>
          </Paper>

          <Switch>
            <Route
              path={appRoutes.reportsContactTracingContactSummary}
              render={() => (
                <ContactTracingReportContactSummaryForceGraph
                  selectedUserName={selectedUser.name}
                  contactSummaries={contactSummaries}
                />
              )}
            />

            <Route
              path={appRoutes.reportsContactTracingContactSummaryChart}
              render={() => (
                <ContactTracingReportContactSummaryChart
                  selectedUserName={selectedUser.name}
                  contactSummaries={contactSummaries}
                />
              )}
            />

            <Route
              path={appRoutes.reportsContactTracingContactSummaryGrid}
              render={() => (
                <ContactTracingReportContactSummaryGrid
                  selectedUserName={selectedUser.name}
                  contactSummaries={contactSummaries}
                />
              )}
            />

            <Route
              path={appRoutes.reportsContactTracingContactDetailsGrid}
              render={() => (
                <ContactTracingReportContactDetailsGrid
                  selectedUserName={selectedUser.name}
                  contactEvents={contactEvents}
                />
              )}
            />
          </Switch>
        </div>
      )}
    </div>
  );
});

export default ContactTracingReportPage;
