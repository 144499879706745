import React from 'react';

import { HorizontalBar } from 'react-chartjs-2';
import { toHHMMSS } from '../../../common/utils';

const ContactTracingReportContactSummaryChart = ({
  selectedUserName,
  contactSummaries,
}) => {
  const chartData = {
    labels: contactSummaries?.map(
      (contactSummary) => contactSummary.detectedUserName
    ),
    datasets: [
      {
        label: 'Total Contact Duration (Minutes)',
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: contactSummaries?.map(
          (contactSummary) =>
            Math.round((contactSummary.totalDurationInSeconds / 60) * 100) / 100
        ),
        backgroundColor: 'orange',
        totalEventsData: contactSummaries?.map(
          (contactSummary) => contactSummary.totalNumberOfEvents
        ),
      },
    ],
  };

  return contactSummaries && contactSummaries.length === 0 ? (
    <p>No contact events found.</p>
  ) : (
    <HorizontalBar
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: { min: 0 },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, chartData) => {
              const minutes = chartData.datasets[0].data[tooltipItem.index];
              const seconds = Math.floor(minutes * 60);
              return `Total Contact Duration (HH:MM:SS): ${toHHMMSS(seconds)}`;
            },
            afterLabel: (tooltipItem, chartData) => {
              return `Total Contact Events: ${
                chartData.datasets[0].totalEventsData[tooltipItem.index]
              }`;
            },
          },
        },
      }}
    ></HorizontalBar>
  );
};

export default ContactTracingReportContactSummaryChart;
