import React, { useState, useEffect } from 'react';

import {
  makeStyles,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { useProgress } from '../../../../providers/ProgressProvider';
import { useApi } from '../../../../providers/ApiProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useLoginAccess } from '../../../../providers/LoginAccessProvider';
import CenteredProgress from '../../../CenteredProgress';

const green = '#4da750';

const useStyles = makeStyles({
  card: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  padRight: {
    paddingRight: '2rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

const AverageContactOverTimeReport = ({ selectedFromDate, selectedToDate }) => {
  const classes = useStyles();

  const { selectedOrganisationId } = useLoginAccess();
  const { showProgress, hideProgress } = useProgress();
  const { api } = useApi();
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [reportLabels, setReportLabels] = useState(
    Array.from(Array(24)).map((_) => '')
  );
  const [reportValues, setReportValues] = useState(
    Array.from(Array(24)).map((_) => 0)
  );
  const [reportColors, setReportColors] = useState(
    Array.from(Array(24)).map((_) => green)
  );

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      showProgress();

      const queryString = `?from=${format(
        selectedFromDate,
        'yyyy-MM-dd'
      )}&to=${format(selectedToDate, 'yyyy-MM-dd')}`;

      const reportData = await api(
        `/api/reports/average-contact-minutes-per-user-per-hour${queryString}`
      );

      setReportLabels(reportData.data.hours);
      setReportValues(reportData.data.averageContactEventMinutesPerUserPerHour);
      setReportColors(reportData.data.colors);
    } catch (e) {
      showSnackbar('Server connection error.');
    } finally {
      setIsLoading(false);
      hideProgress();
    }
  };

  useEffect(() => {
    if (!selectedOrganisationId || !selectedFromDate || !selectedToDate) {
      return;
    }

    fetchReportData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId, selectedFromDate, selectedToDate]);

  return (
    <CenteredProgress isLoading={isLoading}>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h6">
              Average Contact Minutes Per User Per Hour
            </Typography>
          </div>

          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  position: 'relative',
                  height: '300px',
                }}
              >
                <Line
                  data={{
                    labels: reportLabels,
                    datasets: [
                      {
                        label: 'Average Contact Minutes Per User Per Hour',
                        data: reportValues,
                        backgroundColor: reportColors,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{ ticks: { min: 0 } }],
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CenteredProgress>
  );
};

export default AverageContactOverTimeReport;
