import React, { useState, useEffect } from 'react';

import { format, isAfter, isBefore } from 'date-fns';
import MaterialTable from 'material-table';
import { toHHMMSS } from '../../../common/utils';
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Grid,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles({
  select: {
    minWidth: '8rem',
  },
});

const contactEventFilters = [
  {
    index: 0,
    seconds: 0,
    text: 'All Contact Events',
  },
  {
    index: 1,
    seconds: 10,
    text: 'Events Longer than 10 seconds',
  },
  {
    index: 2,
    seconds: 30,
    text: 'Events Longer than 30 seconds',
  },
  {
    index: 3,
    seconds: 60,
    text: 'Events Longer than 1 minute',
  },
];

const ContactTracingReportContactDetailsGrid = ({
  selectedUserName,
  contactEvents,
}) => {
  const classes = useStyles();

  const [
    contactDurationFilterValue,
    setContactDurationSelectedValue,
  ] = useState(1);

  const onContactDurationFilterChange = (e) => {
    setContactDurationSelectedValue(e.target.value);
  };

  const [filteredContactEvents, setFilteredContactEvents] = useState([]);

  useEffect(() => {
    if (!contactEvents) {
      return;
    }

    setFilteredContactEvents(
      contactEvents.filter((contactEvent) => {
        return (
          contactEvent.durationInSeconds >=
          contactEventFilters[contactDurationFilterValue].seconds
        );
      })
    );
  }, [contactEvents, contactDurationFilterValue]);

  return (
    <div id="contact-tracing-report-event-details">
      {contactEvents && contactEvents.length === 0 && (
        <p>No contact events found.</p>
      )}

      {contactEvents && contactEvents.length > 0 && (
        <MaterialTable
          columns={[
            { title: 'Selected User', emptyValue: selectedUserName },
            { title: 'Contact User', field: 'detectedUserName' },
            {
              title: 'Contact Duration (HH:MM:SS)',
              render: (row) => toHHMMSS(row.durationInSeconds),
              customSort: (a, b) => {
                return a.durationInSeconds - b.durationInSeconds;
              },
              customFilterAndSearch: () => true,
              filterComponent: () => {
                return (
                  <>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item style={{ marginRight: '0.5rem' }}>
                        <FilterListIcon />
                      </Grid>
                      <Grid item>
                        <FormControl className={classes.select}>
                          <Select
                            value={contactDurationFilterValue}
                            onChange={onContactDurationFilterChange}
                          >
                            {contactEventFilters.map((contactEventFilter) => (
                              <MenuItem
                                key={contactEventFilter.index}
                                value={contactEventFilter.index}
                              >
                                {contactEventFilter.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                );
              },
            },
            {
              title: 'Contact Start Date/Time',
              render: (row) => format(row.start, 'EEE do LLL HH:mm'),
              customSort: (a, b) => {
                if (isAfter(a.start, b.start)) {
                  return 1;
                } else if (isBefore(a.start, b.start)) {
                  return -1;
                }
                return 0;
              },
              defaultSort: 'desc',
            },
          ]}
          data={filteredContactEvents}
          title=""
          options={{
            draggable: false,
            pageSize: 100,
            pageSizeOptions: [100, 200, 500],
            emptyRowsWhenPaging: false,
            filtering: true,
          }}
        />
      )}
    </div>
  );
};

export default ContactTracingReportContactDetailsGrid;
