import React, { useState, useEffect, useRef } from 'react';

import {
  makeStyles,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { useProgress } from '../../../../providers/ProgressProvider';
import { useApi } from '../../../../providers/ApiProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useLoginAccess } from '../../../../providers/LoginAccessProvider';
import CenteredProgress from '../../../CenteredProgress';
import GraphScaleSelect from './GraphScaleSelect';
import { constants } from '../../../../common/constants';

const green = '#4da750';

const useStyles = makeStyles({
  card: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  padRight: {
    paddingRight: '2rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

const ContactPerUserOverTimeReport = ({ selectedFromDate, selectedToDate }) => {
  const classes = useStyles();

  const chartRef = useRef();

  const { selectedOrganisationId } = useLoginAccess();
  const { showProgress, hideProgress } = useProgress();
  const { api } = useApi();
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [reportLabels, setReportLabels] = useState(
    Array.from(Array(24)).map((_) => '')
  );

  const [reportDatasets, setReportDatasets] = useState([
    {
      label: 'User Contact Minutes Per Hour',
      data: Array.from(Array(24)).map((_) => 0),
      backgroundColor: Array.from(Array(24)).map((_) => green),
      fill: false,
      cubicInterpolationMode: 'monotone',
    },
  ]);

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      showProgress();

      const queryString = `?from=${format(
        selectedFromDate,
        'yyyy-MM-dd'
      )}&to=${format(selectedToDate, 'yyyy-MM-dd')}`;

      const reportData = await api(
        `/api/reports/contact-minutes-per-user-per-hour${queryString}`
      );

      setReportLabels(reportData.data.hours);

      const datasets = reportData.data.users.map((user) => ({
        label: `${user.name} Contact Minutes Per Hour`,
        data: user.contactEventMinutesPerHour,
        backgroundColor: user.colors,
        fill: false,
        cubicInterpolationMode: 'monotone',
      }));
      setReportDatasets(datasets);
    } catch (e) {
      showSnackbar('Server connection error.');
    } finally {
      setIsLoading(false);
      hideProgress();
    }
  };

  const [maxMinutes, setMaxMinutes] = useState(constants.graphScaleAutoValue);

  const onMaxMinutesChanged = (value) => {
    setMaxMinutes(value);
  };

  useEffect(() => {
    if (!selectedOrganisationId || !selectedFromDate || !selectedToDate) {
      return;
    }

    fetchReportData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId, selectedFromDate, selectedToDate]);

  return (
    <CenteredProgress isLoading={isLoading}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item xs={11} style={{ textAlign: 'center' }}>
              <Typography variant="h6">
                Contact Minutes Per User Per Hour
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <GraphScaleSelect
                selectedValue={maxMinutes}
                onValueChanged={onMaxMinutesChanged}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  position: 'relative',
                  height: '300px',
                }}
              >
                <Line
                  ref={chartRef}
                  data={{
                    labels: reportLabels,
                    datasets: reportDatasets,
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            max:
                              maxMinutes !== constants.graphScaleAutoValue
                                ? maxMinutes
                                : undefined,
                          },
                        },
                      ],
                    },
                    onHover: (e) => {
                      const elements = chartRef.current.chartInstance.getElementAtEvent(
                        e
                      );
                      if (elements.length === 0) {
                        if (
                          reportDatasets.some(
                            (dataset) => dataset.borderColor === '#0078d4'
                          )
                        ) {
                          const updatedReportDatasets = [...reportDatasets];
                          updatedReportDatasets.forEach(
                            (dataset) =>
                              (dataset.borderColor = 'rgba(0, 0, 0, 0.1)')
                          );
                          setReportDatasets(updatedReportDatasets);
                        }
                      } else {
                        const datasetIndex = elements[0]._datasetIndex;

                        const updatedReportDatasets = [...reportDatasets];
                        updatedReportDatasets.forEach(
                          (dataset) =>
                            (dataset.borderColor = 'rgba(0, 0, 0, 0.1)')
                        );
                        updatedReportDatasets[datasetIndex].borderColor =
                          '#0078d4';
                        setReportDatasets(updatedReportDatasets);
                      }
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CenteredProgress>
  );
};

export default ContactPerUserOverTimeReport;
