import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Icon,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  marginRight: {
    marginRight: '1rem',
  },
});

const DashboardCard = ({
  width,
  icon,
  color,
  title,
  subtitle,
  counter,
  subCounter,
}) => {
  const classes = useStyles();

  return (
    <Grid item md={width}>
      <Card>
        <CardContent>
          {/* Icon and Title */}
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item className={classes.marginRight}>
              <Icon style={{ color: color }}>{icon}</Icon>
            </Grid>

            <Grid item>
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                style={{ color: color }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>

          {/* Subtitle */}
          <div style={{ minHeight: '3.5rem' }}>
            <Typography color="textSecondary" gutterBottom>
              {subtitle}
            </Typography>
          </div>

          {/* Counters */}
          <Grid container wrap="nowrap">
            <Grid
              container
              direction="column"
              style={{
                backgroundColor: color,
                color: '#fff',
                borderRadius: '10px',
              }}
              justify="center"
            >
              <Grid item>
                <Typography align="center" variant="h2">
                  {counter}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>

            <Grid
              container
              direction="column"
              style={{
                backgroundColor: color,
                color: '#fff',
                borderRadius: '8px',
              }}
              justify="center"
            >
              <Grid item>
                <Typography align="center" variant="h4">
                  {subCounter}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DashboardCard;
