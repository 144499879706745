export const appRoutes = {
  reports: '/reports',
  reportsContactTracing: '/reports/contact-tracing',
  reportsContactTracingContactSummary:
    '/reports/contact-tracing/contact-summary',
  reportsContactTracingContactSummaryChart:
    '/reports/contact-tracing/contact-summary-chart',
  reportsContactTracingContactSummaryGrid:
    '/reports/contact-tracing/contact-summary-grid',
  reportsContactTracingContactDetailsGrid:
    '/reports/contact-tracing/contact-details-grid',
  manageUsers: '/manage-users',
  viewDevices: '/manage-devices',
  manageDeviceAssignments: '/manage-device-assignments',
  dashboard: '/dashboard',
  contactReports: '/contact-reports',
};
