import React from 'react';
import { Grid, Typography, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

const Header = ({ text, icon }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="flex-end">
        <Grid item style={{ marginRight: '0.5rem' }}>
          {icon}
        </Grid>
        <Grid item>
          <Typography variant="h6">{text}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>
    </>
  );
};

export default Header;
