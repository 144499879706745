import React, { createContext, useContext } from 'react';

import axios from 'axios';

import { useLoginAccess } from './LoginAccessProvider';
import { useAuth0 } from '@auth0/auth0-react';

const ApiContext = createContext();
export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
  const { selectedOrganisationId } = useLoginAccess();

  const { getAccessTokenSilently } = useAuth0();

  const api = async (url, method = 'GET', data) => {
    if (!selectedOrganisationId) {
      throw new Error('No selectedOrganisationId was found');
    }

    let token = await getAccessTokenSilently();

    return await axios(`${process.env.REACT_APP_API_URL}${url}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        OrganisationId: selectedOrganisationId,
      },
      data: data,
    });
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};
